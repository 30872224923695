<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      {{ item ? 'Update Classroom Form' : 'New Classroom Form' }}
    </template>
    <template slot="body">
      <ValidationObserver ref="form">
        <FormText
          v-model="form.name"
          label="Name"
          asterisk
        />
        <FormText
          v-model="form.ip"
          label="IP"
          asterisk
        />
        <BuildingsSelect
          v-model="form.building_id"
          asterisk
        />
      </ValidationObserver>
    </template>
    <template slot="footer">
      <md-button
        class="md-success pull-right"
        @click="saveClassroom"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import { FormText } from '@/components/Inputs';
import BuildingsSelect from '@/components/Inputs/selects/BuildingsSelect.vue';

export default {
  components: {
    FormText,
    BuildingsSelect,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      name: null,
      ip: null,
      building_code: null,
    },
  }),
  mounted() {
    if (this.item) {
      this.form = this.item;
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveClassroom() {
      this.$refs.form.validate().then((res) => {
        if (res) {
          if (this.form.classroom_name_id) {
            this.request(`classroom_machines/${this.form.classroom_name_id}`, 'put', { ...this.form }, () => {
              this.fireSuccess('Classroom updated successfuly');
              this.$emit('onSaved');
              this.close();
            });
          } else {
            this.request('classroom_machines', 'post', { ...this.form }, () => {
              this.fireSuccess('Classroom created successfuly');
              this.$emit('onSaved');
              this.close();
            });
          }
        } else {
          this.fireError('Please, complete all the required fields before.');
        }
      });
    },
  },
};
</script>
<style></style>
