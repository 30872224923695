<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-just-icon md-round md-primary pull-right"
          @click="newClassroom"
        >
          <md-icon>add</md-icon>
          <md-tooltip>New Classroom</md-tooltip>
        </md-button>
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getClassrooms"
          @onEdit="onEdit"
          @onShowDetails="onShowDetails"
          @onRemove="onRemove"
        />
      </div>
    </div>

    <ClassroomMachinesForm
      v-if="showModal"
      :item="selectedClassroom"
      @close="closeModal"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import ClassroomMachinesForm from './ClassroomMachinesForm';

export default {
  components: {
    VueTable,
    ClassroomMachinesForm,
  },
  props: [],
  data() {
    return {
      showModal: false,
      selectedClassroom: null,
      vTable: {
        headers: [
          {
            title: 'name',
            sortable: true,
          },
          {
            title: 'ip',
            mask: 'IP',
            sortable: true,
          },
          {
            title: 'building.code',
            mask: 'Building Code',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit',
            callback: 'onEdit',
            icon: 'edit',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Remove',
            callback: 'onRemove',
            icon: 'close',
          },
        ],
        values: {},
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getClassrooms(params) {
      this.request('classroom_machines', 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    newClassroom() {
      this.showModal = true;
      this.selectedClassroom = null;
    },
    onEdit(item) {
      this.showModal = true;
      this.selectedClassroom = { ...item };
    },
    closeModal() {
      this.showModal = false;
      this.selectedClassroom = null;
    },
    onRemove(item) {
      this.request(`classroom_machines/${item.classroom_name_id}`, 'delete', {}, (data) => {
        this.fireSuccess(data);
        this.$refs.vtable.init();
      });
    },
  },
};
</script>

<style scoped>
</style>
