<template>
  <tabs
    :tab-name="[
      'Classroom Machines',
      'Staff Machines',
    ]"
    color-button="success"
  >
    <template slot="tab-pane-1">
      <ClassroomMachines />
    </template>
    <template slot="tab-pane-2">
      <StaffMachines />
    </template>
  </tabs>
</template>

<script>
import { Tabs } from '@/components';
import ClassroomMachines from './ClassroomMachines.vue';
import StaffMachines from './StaffMachines.vue';

export default {
  components: {
    Tabs,
    ClassroomMachines,
    StaffMachines,
  },
};
</script>
